












import Vue from 'vue'
import text from '@/assets/texts/about.json'
import Card from '@/components/Card.vue'

export default Vue.extend({
  name: 'About',
  data () {
    return {
      text: text
    }
  },
  components: {
    Card
  }
})
