

















































































































import Vue from 'vue'
export default Vue.extend({
  name: 'Contact',
  props: {
  },
  data: () => ({
    valid: false,
    innerWidth: window.innerWidth,
    firstname: '',
    lastname: '',
    nameRules: [
      (v) => !!v || 'Name ist ein Pflichtfeld.',
      (v) => v.length <= 30 || 'Name ist zu lang'
    ],
    email: '',
    emailRules: [
      (v) => !!v || 'E-Mail ist ein Pflichtfeld',
      (v) => /.+@.+/.test(v) || 'E-mail muss gültig sein.'
    ],
    message: '',
    solution: '',
    solutionRules: [
      (v) => (v.length < 10 && v.length > 0) || 'Geben Sie einen gültigen Wochentag ein.'
    ],
    days: ['Sontag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']
  }),
  methods: {
    // do something
    submitForm () {
      // is solution correct?
      const isValid = this.validDay(this.solution)
      const formOk = (this.$refs.form as Vue & {validate: () => boolean}).validate()
      if (isValid && formOk) {
        // send mail to Stephie...
        window.open(`mailto:stephanieschlaeger@hotmail.com?subject=Anfrage über Website von ${this.lastname}&body=${this.message}`)
      }
    },
    validDay (enteredDay: string): boolean {
      const today = new Date(Date.now()).getDay()
      enteredDay = enteredDay.toLowerCase()
      switch (enteredDay) {
        case 'sonntag': return today === 0
        case 'montag': return today === 1
        case 'dienstag': return today === 2
        case 'mittwoch': return today === 3
        case 'donnerstag': return today === 4
        case 'freitag': return today === 5
        case 'samstag': return today === 6
      }
      return false
    }
  },
  created () {
    window.addEventListener('keydown', e => {
      if (e.key === 'Enter') {
        this.submitForm()
      }
    })
  }
})
