








import Vue from 'vue'
import Card from '@/components/Card.vue'
import text from '@/assets/texts/lessons.json'

// import Header from './components/Header.vue'
export default Vue.extend({
  name: 'Lessons',
  data () {
    return {
      text: text
    }
  },
  components: {
    Card
  }
})

